<template>
    <div class="layout-subcontainer cmp-subcont">
        <div class="box-wrap">
            <div class="pa-0">
                <h1 class="tit-h1 d-flex">계약 고객 관리
                    <div class="ml-auto">
                        <v-btn outlined small class="btn-default ml-2" @click="fnSearchDataReset">초기화</v-btn>
                        <v-btn outlined small class="btn-etc2 ml-2" @click="fnGetListData(true, 1)">조회</v-btn>
                    </div>
                </h1>
                <div class="box-ct mt-2">
                    <div class="table-form d-flex">
                        <table>
                            <colgroup>
                                <col width="6%" />
                                <col width="17%" />
                                <col width="6%" />
                                <col width="17%" />
                                <col width="6%" />
                                <col width="17%" />
                                <col width="6%" />
                                <col width="17%" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <!-- <v-badge dot color="#EF9191">건설사명</v-badge> -->
                                        건설사명
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-autocomplete
                                                :items="ctcpTypeCds"
                                                item-text="CD_NM"
                                                item-value="CD"
                                                outlined
                                                hide-details
                                                @change="fnChnCtcpCd(S_CTCP_CODE)"
                                                v-model="S_CTCP_CODE"
                                                placeholder="선택"
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        <!-- <v-badge dot color="#EF9191">사업지명</v-badge> -->
                                        사업지명
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-autocomplete
                                                :items="bussTypeCds"
                                                item-text="CD_NM"
                                                item-value="CD"
                                                outlined
                                                v-model="S_BUSS_CODE"
                                                :disabled="bussTypeCds.length == 0 ? true : false"
                                                placeholder="선택"
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        초기성향
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-autocomplete 
                                                class="form-inp full"
                                                v-model="S_CUST_PPST_CDS" 
                                                :items="sUserPpsityCd" 
                                                item-text="CD_NM"
                                                item-value="CD"
                                                outlined
                                                multiple
                                                hide-details
                                                placeholder="선택"
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip small>
                                                        <span>{{ item.CD_NM }}</span>
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        진행상태
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-autocomplete
                                                class="form-inp full"
                                                :items="procStatItems"
                                                item-text="CD_NM"
                                                item-value="CD"
                                                outlined
                                                hide-details
                                                placeholder="선택"
                                                v-model="S_PROC_STAT_CD"
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <v-badge dot color="#EF9191">기간</v-badge>
                                    </th>
                                    <td>
                                        <v-menu 
                                            v-model="menu1" 
                                            :close-on-content-click="false" 
                                            :nudge-right="40"
                                            transition="scale-transition" 
                                            offset-y 
                                            min-width="auto" 
                                            content-class="calendar-modal"
                                            color="#FFF"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="dateRangeText"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    readonly
                                                    outlined
                                                    hide-details
                                                    class="form-inp icon-calendar full"
                                                    append-icon="svg-calendar"
                                                ></v-text-field>
                                            </template>
                                            <div class="datepicker-range">
                                                <v-date-picker
                                                    v-model="DATES[0]"
                                                    no-title
                                                    color="#F4B228"
                                                    locale="ko-KR"
                                                    :first-day-of-week="0" 
                                                    :day-format="mixin_getDate" 
                                                    @change="fnStartDate"
                                                ></v-date-picker>
                                                <v-date-picker
                                                    v-model="DATES[1]"
                                                    color="#F4B228"
                                                    no-title
                                                    show-current="false"
                                                    :min="DATES[0]"
                                                    locale="ko-KR"
                                                    :first-day-of-week="0" 
                                                    :day-format="mixin_getDate" 
                                                    @change="fnEndDate"
                                                ></v-date-picker>
                                            </div>
                                            <div class="text-center pt-3 pb-3">
                                                <v-btn
                                                    outlined
                                                    small
                                                    class="btn-point ml-3"
                                                    @click="menu1 = false"
                                                >확인
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </td>
                                    <th scope="row">
                                        동
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-text-field
                                                class="form-inp full"
                                                outlined
                                                hide-details
                                                v-model="S_DONG"
                                                v-on:keyup.enter="fnGetListData(true, 1)"
                                            >
                                            </v-text-field>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        호수
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-text-field
                                                class="form-inp full"
                                                outlined
                                                hide-details
                                                v-model="S_HOSU"
                                                v-on:keyup.enter="fnGetListData(true, 1)"
                                            >
                                            </v-text-field>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        주소
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-text-field
                                                class="form-inp full"
                                                outlined
                                                hide-details
                                                v-model="S_ADDRESS"
                                                v-on:keyup.enter="fnGetListData(true, 1)"
                                            >
                                            </v-text-field>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        이름
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-text-field
                                                class="form-inp full"
                                                outlined
                                                hide-details
                                                v-model="S_CONTR_CUST_NAME"
                                                v-on:keyup.enter="fnGetListData(true, 1)"
                                            >
                                            </v-text-field>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        전화번호
                                    </th>
                                    <td>
                                        <div>
                                            <v-text-field
                                                class="form-inp full"
                                                outlined
                                                hide-details
                                                v-model="S_CONTR_CUST_PHONE"
                                                v-on:keyup.enter="fnGetListData(true, 1)"
                                            >
                                            </v-text-field>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        카카오톡 이름
                                    </th>
                                    <td>
                                        <div>
                                            <v-text-field
                                                class="form-inp full"
                                                outlined
                                                hide-details
                                                v-model="S_CUST_NAME"
                                                v-on:keyup.enter="fnGetListData(true, 1)"
                                            >
                                            </v-text-field>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        카카오톡 전화번호
                                    </th>
                                    <td>
                                        <div>
                                            <v-text-field
                                                class="form-inp full"
                                                outlined
                                                hide-details
                                                v-model="S_CUST_PHONE"
                                                v-on:keyup.enter="fnGetListData(true, 1)"
                                            >
                                            </v-text-field>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        외부 유입
                                    </th>
                                    <td colspan="7">
                                        <v-checkbox 
                                            :on-icon="'svg-chk-on'" 
                                            :off-icon="'svg-chk-off'" 
                                            class="form-inp table-form-tag"
                                            v-model="S_OUT_IN_STATUS"
                                        >
                                        </v-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-ct mt-3">
                    <h1 class="tit-h1 d-flex">계약 고객 목록
                        <div class="ml-auto">
                            <v-btn
                                outlined
                                class="btn-etc1 ml-2"
                                @click="fnNewAddPop"
                            >
                                신규
                            </v-btn>
                            <v-btn 
                                outlined 
                                class="btn-default ml-2" 
                                @click="fnSampleExcelDown"
                            >
                                샘플 다운로드
                            </v-btn>
                            <v-btn
                                outlined
                                class="btn-etc2 ml-2"
                                @click="fnExcelUploadPop"
                            >
                                엑셀 업로드
                            </v-btn>
                            <v-btn
                                outlined
                                class="btn-default ml-2"
                                @click="fnDownloadExcel('계약 고객 관리')"
                            >
                                엑셀 다운로드
                            </v-btn>
                            <v-btn
                                outlined
                                class="btn-etc ml-2"
                                @click="fnDeleteRowConfirm"
                            >
                                삭제
                            </v-btn>
                        </div>
                    </h1>

                    <div class="mt-2">
                        <v-data-table
                            dense
                            fixed-header
                            height="490px"
                            :headers="gridDataHeaders"
                            :items="gridDataText"
                            :items-per-page="itemsPerPage"
                            item-key="IDX"
                            hide-default-footer
                            class="grid-default"
                            @click:row="fnGridRowClick"
                            @dblclick:row="fnGridRowDbClick"
                            v-model="SELECT_ROWS"
                            :item-class="clickActive"
                            no-data-text="검색된 결과가 없습니다."
                            show-select
                        >
                            <!-- 초기성향 -->
                            <template v-slot:item.CUST_PPST_CD={item}>
                                <v-select 
                                    class="form-inp full" 
                                    v-model="CUST_PPST_CDS[item.IDX]" 
                                    :items="userPpsityCd" 
                                    item-text="CD_NM"
                                    item-value="CD"
                                    outlined
                                    hide-details
                                    placeholder="선택"
                                    @change="fnRowPpstCdChan(item)"
                                >
                                </v-select>
                            </template>

                            <!-- 진행사항 -->
                            <template v-slot:item.PROC_STAT_CD={item}>
                                <v-select
                                    class="form-inp full"
                                    v-model="PROC_STAT_CDS[item.IDX]"
                                    :items="procStatItems"
                                    item-text="CD_NM"
                                    item-value="CD"
                                    outlined
                                    hide-details
                                    placeholder="선택"
                                    @change="fnRowProcStatCdChan(item)"
                                >
                                </v-select>
                            </template>

                            <template v-slot:item.IMG_QUTN_RPLY={item}>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            dark 
                                            small 
                                            class="btn-main-search pa-0 ml-2" 
                                            min-width="32px"
                                            height="32px" 
                                            plain
                                            :disabled="item.IMG_QUTN_RPLY=='' ? true : false"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon small class="svg-main-search2"></v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        <img
                                            v-if="item.IMG_QUTN_RPLY!=''"
                                            v-for="(imgItem, idx) in item.IMG_QUTN_RPLY.split(',')"
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            :src="baseUrl+imgDefPath+imgItem.split(':')[1]"
                                            :width=750 :height=650 />
                                    </span>
                                </v-tooltip>
                            </template>

                            <!-- 마케팅 동의 여부 -->
                            <template v-slot:item.MKTG_AGRE_YN={item}>
                                <v-select
                                    class="form-inp full"
                                    v-model="MKTG_AGRE_YNS[item.IDX]"
                                    :items="YN_ITEMS"
                                    item-text="CD_NM"
                                    item-value="CD"
                                    outlined
                                    hide-details
                                    placeholder="선택"
                                    @change="fnRowMkAgYnChan(item)"
                                >
                                </v-select>
                            </template>

                            <!-- 개인정보 수집 동의 여부 -->
                            <template v-slot:item.PRSN_AGRE_YN={item}>
                                <v-select
                                    class="form-inp full"
                                    v-model="PRSN_AGRE_YNS[item.IDX]"
                                    :items="YN_ITEMS"
                                    item-text="CD_NM"
                                    item-value="CD"
                                    outlined
                                    hide-details
                                    placeholder="선택"
                                    @change="fnRowPaYnChan(item)"
                                >
                                </v-select>
                            </template>

                        </v-data-table>
                        <div class="grid-paging text-center pt-2">
                            <span class="grid-total-count">총 <strong>{{ totCount }}</strong>건</span>
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                                :total-visible="totalVisible"
                                @input="fnGetListData(true, page)"
                                next-icon="svg-paging-next"
                                prev-icon="svg-paging-prev"
                            ></v-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-if="dialogOMP0410Excel" max-width="690" max-height="390" content-class="square-modal min-auto" persistent v-model="dialogOMP0410Excel">
            <dialog-OMP0410
                headerTitle="계약 고객 엑셀 업로드"
                :param="OMP0410Param"
                p01
                @listCall="fnListCall"
                @hideExcel="fnHideDialog('OMP0410Excel')"
                @hide="fnHideDialog('OMP0410')"
                @submit="fnSubmitDialog('OMP0410')"
            >
            </dialog-OMP0410>
        </v-dialog>

        <v-dialog v-if="dialogOMP0410" max-width="1090" max-height="390" content-class="square-modal min-auto" persistent v-model="dialogOMP0410">
            <dialog-OMP0410
                headerTitle="계약 고객 VIEW"
                :param="OMP0410Param"
                p02
                @listCall="fnListCall"
                @hide="fnHideDialog('OMP0410')"
                @submit="fnSubmitDialog('OMP0410')"
            >
            </dialog-OMP0410>
        </v-dialog>
    </div>
</template>

<script>
    import DialogOMP0410 from "./OMP0410.vue"

    export default {
        name: 'MENU_OMM0400',
        components: {
            DialogOMP0410
        },
        data() {
            return {
                dialogOMP0410Excel: false,
                dialogOMP0410: false,
                OMP0410Param: {},
                EXCEL_HEADER_KEYS: [],
                EXCEL_UPD_SAM_FILE_NM: '계약 고객 양식 템플릿',
                EXCEL_UPD_SAM_SHEET_NM: '계약 고객 목록',

                USER_ID: '',
                USER_AUTH_CHK: '',
                USER_AUTH_BOOL: false,
                baseUrl:process.env.VUE_APP_API_URL,
                imgDefPath:'api/file/hlw/images/',
                cnslrStatCds: '',
                allCodeList: [],
                ctcpTypeCds: [],
                S_CTCP_CODE: '',
                bussTypeCds: [],
                S_BUSS_CODE: '',
                DATE_TEXT: '설문참여',
                menu1:false,
                DATES: [
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10),
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10),
                ],
                SELECT_ROWS: [],

                CUST_PPST_CDS: [],    //초기성향 목록
                PROC_STAT_CDS: [],      //진행상태 목록

                S_CUST_NAME: '',
                S_CUST_PHONE: '',
                S_CONTR_CUST_NAME: '',
                S_CONTR_CUST_PHONE: '',
                S_CUST_RES_NO: '',
                ROW_NUMBER: '',
                S_CMP_DATES: '',
                procStatItems: [],
                S_PROC_STAT_CD: '',
                sUserPpsityCd: [],
                userPpsityCd: [],
                S_CUST_PPST_CDS: [],
                S_OUT_IN_STATUS: false,
                S_HOSU: '',
                S_ADDRESS: '',
                S_DONG: '',
                MKTG_AGRE_YNS: [],
                PRSN_AGRE_YNS: [],

                YN_ITEMS: [
                    {CD:'', CD_NM:'선택'}
                    ,{CD:'Y', CD_NM:'예'}
                    ,{CD:'N', CD_NM:'아니오'}
                ],

                gridDataText: [],
                gridDataHeaders: [
                    { text:"No", value:"IDX", align:"center", width:"57px", sortable:true },
                    { text:"건설사", value:"CTCP_NAME", align:"center", width:"100px", sortable:true },
                    { text:"사업지", value:"BUSS_NAME", align:"center", width:"100px", sortable:true },
                    { text:"동", value:"DONG", align:"center", width:"77px", sortable:true },
                    { text:"호수", value:"HOSU", align:"center", width:"100px", sortable:true },
                    { text:"이름", value:"CONTR_CUST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"전화번호", value:"CONTR_CUST_PHONE", align:"center", width:"130px", sortable:true },
                    { text:"주민번호", value:"CUST_RES_NO", align:"center", width:"150px", sortable:true },
                    { text:"주소", value:"ADDRESS", align:"center", width:"200px", sortable:true },
                    { text:"카카오톡 이름", value:"CUST_NAME", align:"center", width:"110px", sortable:true },
                    { text:"카카오톡 핸드폰", value:"CUST_PHONE", align:"center", width:"120px", sortable:true },
                    { text:"마케팅 활용동의", value:"MKTG_AGRE_YN", align:"center", width:"120px", sortable:true },
                    { text:"개인정보수집", value:"PRSN_AGRE_YN", align:"center", width:"100px", sortable:true },
                    { text:"초기성향", value:"CUST_PPST_CD", align:"center", width:"100px", sortable:true },
                    { text:"진행사항", value:"PROC_STAT_CD", align:"center", width:"190px", sortable:true },
                ],

                excelGridDataText: [],
                excelGridDataHeaders: [
                    { text:"No", value:"IDX", align:"center", width:"57px", sortable:true },
                    { text:"건설사", value:"CTCP_NAME", align:"center", width:"100px", sortable:true },
                    { text:"사업지", value:"BUSS_NAME", align:"center", width:"100px", sortable:true },
                    { text:"동", value:"DONG", align:"center", width:"77px", sortable:true },
                    { text:"호수", value:"HOSU", align:"center", width:"100px", sortable:true },
                    { text:"이름", value:"CONTR_CUST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"전화번호", value:"CONTR_CUST_PHONE", align:"center", width:"130px", sortable:true },
                    { text:"주민번호", value:"CUST_RES_NO", align:"center", width:"150px", sortable:true },
                    { text:"주소", value:"ADDRESS", align:"center", width:"200px", sortable:true },
                    { text:"카카오톡 이름", value:"CUST_NAME", align:"center", width:"110px", sortable:true },
                    { text:"카카오톡 핸드폰", value:"CUST_PHONE", align:"center", width:"120px", sortable:true },
                    { text:"마케팅 활용동의", value:"MKTG_AGRE_YN", align:"center", width:"110px", sortable:true },
                    { text:"개인정보수집", value:"PRSN_AGRE_YN", align:"center", width:"100px", sortable:true },
                    { text:"초기성향", value:"CUST_PPST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"진행사항", value:"PROC_STAT_NAME", align:"center", width:"190px", sortable:true },
                ],

                page: 1,
                pageCount: 0,
                totCount: 0,
                itemsPerPage: 20,
                totalVisible: 10,

                alertMsg: {
                    success    : "적용됐습니다.",
                    succDel    : "삭제됐습니다.",
                    errorManChk: "재시도 후 같은 증상 발생시<br>관리자에게 문의하세요.",
                    error      : "시스템 장애가 일어났습니다.<br>관리자에게 문의하세요.",
                    deleteMsg  : "삭제 하시겠습니까?",
                    saveMsg    : "저장 하시겠습니까?",
                },
            }
        },

        methods: {

            //신규 저장 후 호출
            fnListCall() {
                this.fnGetListData(false, 1);
            },

            // 마케팅 동의여부 업데이트
            async fnRowMkAgYnChan(item) {
                let requestData = {
                    headers: {},
                    sendData:{},
                };

                //header 세팅
                requestData.headers["URL"]="/api/hlw/omm/manage/rowBy/mkAgYn/update";

                //sendData 초기화
                requestData.sendData["USER_ID"]=this.USER_ID;
                requestData.sendData["CMP_CNSL_ID"]=item.CMP_CNSL_ID;
                requestData.sendData["MKTG_AGRE_YN"]=this.MKTG_AGRE_YNS[item.IDX];

                let response=await this.common_postCall(requestData, false);
                let header=response.HEADER;

                if (header.ERROR_FLAG){
                    this.common_alert("관리자에게 문의해주세요.", "error");
                    return;
                }else{
                    this.common_alert('수정됐습니다.');
                }
            },

            // 개인정보 수집동의여부 업데이트
            async fnRowPaYnChan(item) {
                let requestData = {
                    headers: {},
                    sendData:{},
                };

                //header 세팅
                requestData.headers["URL"]="/api/hlw/omm/manage/rowBy/paYn/update";

                //sendData 초기화
                requestData.sendData["USER_ID"]=this.USER_ID;
                requestData.sendData["CMP_CNSL_ID"]=item.CMP_CNSL_ID;
                requestData.sendData["PRSN_AGRE_YN"]=this.PRSN_AGRE_YNS[item.IDX];

                let response=await this.common_postCall(requestData, false);
                let header=response.HEADER;

                if (header.ERROR_FLAG){
                    this.common_alert("관리자에게 문의해주세요.", "error");
                    return;
                }else{
                    this.common_alert('수정됐습니다.');
                }
            },

            fnDeleteRowConfirm() {
                if(this.mixin_isEmpty(this.SELECT_ROWS)) {
                    this.common_alert('삭제할 데이터를 목록에서 선택해주세요.', 'chk');
                    return;
                }
                this.common_confirm('삭제하시겠습니까?', this.fnDeleteRowData, null, null, null, 'chk');
            },

            async fnDeleteRowData() {

                let selDelRows = [];
                for (let index = 0; index < this.SELECT_ROWS.length; index++) {
                    const element = this.SELECT_ROWS[index];
                    
                    selDelRows.push(element.CMP_CNSL_ID);
                }
                
                if(!this.mixin_isEmpty(selDelRows)) {

                    let requestData = {
                        headers: {},
                        sendData:{},
                    }
    
                    //header 세팅
                    requestData.headers["URL"]="/api/hlw/omm/mi/manage/row/delete";
    
                    //sendData 초기화
                    requestData.sendData["CMP_CNSL_IDS"]=selDelRows;
                    requestData.sendData["USER_ID"]=this.USER_ID;
                    requestData.sendData["USE_YN"]="N";
    
                    let response=await this.common_postCall(requestData, false);
                    let header=response.HEADER;
    
                    if (header.ERROR_FLAG){
                        this.common_alert(this.alertMsg.error,"error");
                        return;
                    }else{
                        this.common_alert(this.alertMsg.succDel, 'chk');
                        this.fnGetListData(false, 1);
                        this.SELECT_ROWS = [];
                    }
                }else{
                    this.common_alert(this.alertMsg.errorManChk, 'error');
                    return;
                }
            },

            //목록 row 별 초기성향 변경 이벤트
            async fnRowPpstCdChan(item) {

                let requestData = {
                    headers: {},
                    sendData:{},
                }

                //header 세팅
                requestData.headers["URL"]="/api/hlw/omm/mi/manage/rowBy/ppst/update";

                //sendData 초기화
                requestData.sendData["USER_ID"]=this.USER_ID;
                requestData.sendData["CMP_CNSL_ID"]=item.CMP_CNSL_ID;
                requestData.sendData["CUST_PPST_CD"]=this.CUST_PPST_CDS[item.IDX];

                let response=await this.common_postCall(requestData, false);
                let header=response.HEADER;

                if (header.ERROR_FLAG){
                    this.common_alert("관리자에게 문의해주세요.","error");
                    return;
                }else{
                    this.common_alert('수정됐습니다.');
                }
            },

            //목록 row 별 진행상태 변경 이벤트
            async fnRowProcStatCdChan(item) {

                let requestData = {
                    headers: {},
                    sendData:{},
                }

                //header 세팅
                requestData.headers["URL"]="/api/hlw/omm/mi/manage/rowBy/procStat/update";

                //sendData 초기화
                requestData.sendData["USER_ID"]=this.USER_ID;
                requestData.sendData["CMP_CNSL_ID"]=item.CMP_CNSL_ID;
                requestData.sendData["PROC_STAT_CD"]=this.PROC_STAT_CDS[item.IDX];

                let response=await this.common_postCall(requestData, false);
                let header=response.HEADER;

                if (header.ERROR_FLAG){
                    this.common_alert("관리자에게 문의해주세요.","error");
                    return;
                }else{
                    this.common_alert('수정됐습니다.');
                }
            },

            fnSampleExcelDown() {
                this.EXCEL_HEADER_KEYS=['동','호수','이름','전화번호(셀 서식:텍스트 변경 후 기입해주세요.)','주민번호(셀 서식:텍스트 변경 후 기입해주세요.)','주소'];
                this.mixin_common_exportExcel_template(this.EXCEL_UPD_SAM_FILE_NM, this.EXCEL_UPD_SAM_SHEET_NM, this.EXCEL_HEADER_KEYS, []);
            },

            fnExcelUploadPop() {
                this.EXCEL_HEADER_KEYS=['동','호수','이름','전화번호','주민번호','주소'];

                this.OMP0410Param={
                    headerTitle:"계약 고객 엑셀 업로드",
                    type:"F",
                    excelHeaderKeys:this.EXCEL_HEADER_KEYS,
                };

                this.fnShowDialog('OMP0410Excel');
            },

            fnDownloadExcel(fileNm) {

                if(this.mixin_isEmpty(this.gridDataText)) {
                    this.common_alert('조회후 엑셀다운로드를 해주시기 바랍니다.', 'chk');
                    return;
                }

                //엑셀다운로드
                let fileName = fileNm + '_('+this.DATES[0] + '~' +this.DATES[1]+')';

                this.fnExcelGrid(fileName);
            },

            async fnExcelGrid(fileName) {
                if(!this.USER_AUTH_BOOL || this.USER_AUTH_CHK != 'SYSTEMMANAGER') {
                    if(this.mixin_isEmpty(this.S_CTCP_CODE)) {
                        this.common_alert('건설사를 선택해주세요.', 'chk');
                        return;
                    }
                    if(this.mixin_isEmpty(this.S_BUSS_CODE)) {
                        this.common_alert('사업지를 선택해주세요.', 'chk');
                        return;
                    }
                }

                this.excelGridDataText = [];
                this.excelGridDataHeaders = [];

                let requestData = {
                    headers: {
                        SERVICE: this.initHeaders.SERVICE,
                        METHOD: 'list',
                        TYPE: this.initHeaders.TYPE,
                    },
                    sendData:{}
                };

                requestData.headers["URL"] = "/api/hlw/omm/mi/contract/manage/list";

                // sendData 세팅
                requestData.sendData["CTCP_CODE"]=this.S_CTCP_CODE;
                requestData.sendData["BUSS_CODE"]=this.S_BUSS_CODE;
                requestData.sendData["START_DATE"]=this.DATES[0];
                requestData.sendData["END_DATE"]=this.DATES[1];
                requestData.sendData["DONG"]=this.fnGapReplace(this.S_DONG);
                requestData.sendData["HOSU"]=this.fnGapReplace(this.S_HOSU);
                requestData.sendData["ADDRESS"]=this.fnGapReplace(this.S_ADDRESS);
                requestData.sendData["CUST_NAME"]=this.S_CUST_NAME;
                requestData.sendData["CUST_PHONE"]=this.S_CUST_PHONE;
                requestData.sendData["CONTR_CUST_NAME"]=this.S_CONTR_CUST_NAME;
                requestData.sendData["CONTR_CUST_PHONE"]=this.S_CONTR_CUST_PHONE;
                requestData.sendData["CUST_RES_NO"]=this.S_CUST_RES_NO;
                requestData.sendData["CUST_PPST_CD"]=this.mixin_isEmpty(this.S_CUST_PPST_CDS) ? '' : this.S_CUST_PPST_CDS.join();     //초기성향
                requestData.sendData["PROC_STAT_CD"]=this.S_PROC_STAT_CD;   //진행상태
                requestData.sendData["OUT_IN_STATUS"]=this.S_OUT_IN_STATUS ? 'Y' : 'N';     //외부유입 여부
                
                requestData.sendData["PART_TYPE"]='MI';
                requestData.sendData["USE_YN"]='Y';

                let responseGridList = await this.common_postCall(requestData);
                let resHeader = responseGridList.HEADER;
                let resData = responseGridList.DATA;

                // 데이터 조회 오류 시 alert
                if (resHeader.ERROR_FLAG) {
                    this.common_alert(this.alertMsg.error, "error");
                    return false;
                }else{
                    if(!this.mixin_isEmpty(resData)) {
                        this.fnExcelResult(responseGridList, fileName);
                    } else {
                        this.common_alert('해당 조건엔 데이터가 존재하지 않습니다.', 'chk');
                    }
                }
            },

            fnExcelResult(response, fileName){
                this.SELECT_ROWS = [];

                let excelGridDataHeaders_bak = [
                    { text:"No", value:"IDX", align:"center", width:"57px", sortable:true },
                    { text:"건설사", value:"CTCP_NAME", align:"center", width:"100px", sortable:true },
                    { text:"사업지", value:"BUSS_NAME", align:"center", width:"100px", sortable:true },
                    { text:"동", value:"DONG", align:"center", width:"77px", sortable:true },
                    { text:"호수", value:"HOSU", align:"center", width:"100px", sortable:true },
                    { text:"이름", value:"CONTR_CUST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"전화번호", value:"CONTR_CUST_PHONE", align:"center", width:"130px", sortable:true },
                    { text:"주민번호", value:"CUST_RES_NO", align:"center", width:"150px", sortable:true },
                    { text:"주소", value:"ADDRESS", align:"center", width:"200px", sortable:true },
                    { text:"카카오톡 이름", value:"CUST_NAME", align:"center", width:"110px", sortable:true },
                    { text:"카카오톡 핸드폰", value:"CUST_PHONE", align:"center", width:"120px", sortable:true },
                    { text:"마케팅 활용동의", value:"MKTG_AGRE_YN", align:"center", width:"110px", sortable:true },
                    { text:"개인정보수집", value:"PRSN_AGRE_YN", align:"center", width:"100px", sortable:true },
                    { text:"초기성향", value:"CUST_PPST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"진행사항", value:"PROC_STAT_NAME", align:"center", width:"190px", sortable:true },
                ];

                this.excelGridDataHeaders = excelGridDataHeaders_bak;

                if (this.excelGridDataText.length === 0) {
                    return;
                }

                this.excelGridDataText = response.DATA;
                const res = response.DATA;
                let cnt = 0;

                for(var i in res) {

                    res[i].IDX = Number(res[i].IDX);

                    if(res[i]["QUTN_ITEM_NAME"] !== '') {
                        if( cnt == 0 ) {
                            for(var d in res[i]["QUTN_ITEM_NAME"]) {
                                this.excelGridDataHeaders.push({ text: "질문"+(Number(d)+1), value:"QUTN_ITEM_NAME" + d,  align: "center", width: "120px", sortable: true },)
                                this.excelGridDataHeaders.push({ text: "답"+(Number(d)+1), value: "QUTN_ITEM_DETL_CODE" + d,  align: "center", width: "120px", sortable: true },)  
                                this.excelGridDataText[i]["QUTN_ITEM_NAME"+[d]] = res[i]["QUTN_ITEM_NAME"][d];
                                this.excelGridDataText[i]["QUTN_ITEM_DETL_CODE"+[d]] = res[i]["QUTN_ITEM_DETL_CODE"][d];

                                cnt = cnt + 1;  
                            }

                        }else{
                            for(var c in res[i]["QUTN_ITEM_NAME"]) {
                                if( c >= cnt ) {
                                    this.excelGridDataHeaders.push({ text: "질문"+(Number(c)+1), value:"QUTN_ITEM_NAME" + c,  align: "center", width: "120px", sortable: true },)
                                    this.excelGridDataHeaders.push({ text: "답"+(Number(c)+1), value: "QUTN_ITEM_DETL_CODE" + c,  align: "center", width: "120px", sortable: true },) 
                                    this.excelGridDataText[i]["QUTN_ITEM_NAME"+[c]] = res[i]["QUTN_ITEM_NAME"][c];
                                    this.excelGridDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = res[i]["QUTN_ITEM_DETL_CODE"][c]; 

                                    cnt = cnt + 1;
                                }else{
                                    this.excelGridDataText[i]["QUTN_ITEM_NAME"+[c]] = res[i]["QUTN_ITEM_NAME"][c];
                                    this.excelGridDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = res[i]["QUTN_ITEM_DETL_CODE"][c];
                                }
                            }
                        }
                    }
                }

                this.excelGridDataText.forEach((ele, i) => {
                    if(!this.mixin_isEmpty(ele.CUST_PPST_NAME)) {
                        ele['CUST_PPST_CD_BAK'] = ele.CUST_PPST_CD;
                        ele.CUST_PPST_CD = ele.CUST_PPST_NAME;
                    }
                    if(!this.mixin_isEmpty(ele.PROC_STAT_NAME)) {
                        ele['PROC_STAT_CD_BAK'] = ele.PROC_STAT_CD;
                        ele.PROC_STAT_CD = ele.PROC_STAT_NAME;
                    }
                });

                this.mixin_common_Excel(this.excelGridDataHeaders, this.excelGridDataText, fileName, '계약 고객 목록');
            },

            fnNewAddPop() {
                this.OMP0410Param={
                    headerTitle: "계약 고객 신규 등록",
                    type:"I",
                    btnText:'저장',
                    rowData:{},
                };

                this.fnShowDialog('OMP0410');
            },

            fnSubmitDialog(type) {
                if(this.OMP0410Param.data.length>0) {
                    this.fnGetListData(false, 1);
                }
                this.common_alert(this.alertMsg.success, 'chk');
                this.fnHideDialog(type);
            },

            fn0420SubmitDialog(type) {
                this.fnHideDialog(type);
            },

            fnShowDialog(type) {
                this[`dialog${type}`]=true;
            },

            fnHideDialog(type) {
                this[`dialog${type}`]=false;
            },

            fnStartDate(e) {
                this.DATES[0] = e;
            },

            fnEndDate(e) {
                this.DATES[1] = e;
            },

            async fnChnCtcpCd(param) {
                this.bussTypeCds=[];
                this.S_BUSS_CODE = "";

                if (!this.mixin_isEmpty(param)) {
                    
                    if (this.USER_AUTH_BOOL && this.USER_AUTH_CHK == 'SYSTEMMANAGER') {
                        this.bussTypeCds = await this.mixin_mi_busscode_get(param);
                    }else{
                        this.bussTypeCds = await this.mixin_mi_busscode_get(param, this.USER_ID);
                    }
                }
            },

            fnSearchDataReset() {
                this.common_confirm('검색 조건을 초기화하시겠습니까?', this.searchDataReset, null, null, null, 'chk');
            },

            searchDataReset() {
                this.S_CTCP_CODE='';
                this.S_BUSS_CODE='';
                this.S_CUST_RES_NO='';
                this.S_CUST_NAME='';
                this.S_CUST_PHONE='';
                this.S_CONTR_CUST_NAME='';
                this.S_CONTR_CUST_PHONE='';
                this.DATES=[new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10),
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10),];
                this.S_DONG='';
                this.S_HOSU='';
                this.S_ADDRESS='';
                this.S_PROC_STAT_CD='';   //진행상태
                this.S_CUST_PPST_CDS=[];     //초기성향
                this.S_OUT_IN_STATUS=false;    //외부유입 여부
            },

            async fnGetListData(progBool, pg) {

                let progBoolChk = true;
                if(!this.mixin_isEmpty(progBool)) {
                    progBoolChk = progBool
                }

                const gridDataHeaders_bak = [
                    { text:"No", value:"IDX", align:"center", width:"57px", sortable:true },
                    { text:"건설사", value:"CTCP_NAME", align:"center", width:"100px", sortable:true },
                    { text:"사업지", value:"BUSS_NAME", align:"center", width:"100px", sortable:true },
                    { text:"동", value:"DONG", align:"center", width:"77px", sortable:true },
                    { text:"호수", value:"HOSU", align:"center", width:"100px", sortable:true },
                    { text:"이름", value:"CONTR_CUST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"전화번호", value:"CONTR_CUST_PHONE", align:"center", width:"130px", sortable:true },
                    { text:"주민번호", value:"CUST_RES_NO", align:"center", width:"150px", sortable:true },
                    { text:"주소", value:"ADDRESS", align:"center", width:"200px", sortable:true },
                    { text:"카카오톡 이름", value:"CUST_NAME", align:"center", width:"110px", sortable:true },
                    { text:"카카오톡 핸드폰", value:"CUST_PHONE", align:"center", width:"120px", sortable:true },
                    { text:"마케팅 활용동의", value:"MKTG_AGRE_YN", align:"center", width:"110px", sortable:true },
                    { text:"개인정보수집", value:"PRSN_AGRE_YN", align:"center", width:"100px", sortable:true },
                    { text:"초기성향", value:"CUST_PPST_CD", align:"center", width:"100px", sortable:true },
                    { text:"진행사항", value:"PROC_STAT_CD", align:"center", width:"190px", sortable:true },
                ];

                if(!this.USER_AUTH_BOOL || this.USER_AUTH_CHK != 'SYSTEMMANAGER') {
                    if(this.mixin_isEmpty(this.S_CTCP_CODE)) {
                        this.common_alert('건설사를 선택해주세요.', 'chk');
                        return;
                    }
                    if(this.mixin_isEmpty(this.S_BUSS_CODE)) {
                        this.common_alert('사업지를 선택해주세요.', 'chk');
                        return;
                    }
                }

                this.gridDataText = [];
                this.gridDataHeaders = [];
                this.gridDataHeaders = gridDataHeaders_bak;

                let requestData = {
                    headers: {
                        SERVICE: this.initHeaders.SERVICE,
                        METHOD: 'list',
                        TYPE: this.initHeaders.TYPE,
                    },
                    sendData:{}
                };

                requestData.headers["URL"] = "/api/hlw/omm/mi/contract/manage/list";

                this.page = pg;

                let plCnt = 0;
                if(this.page > 1) {
                    plCnt = 1;
                }

                let strRow = ((this.page - 1) * (this.itemsPerPage)) + plCnt;
                let endRow = this.page * this.itemsPerPage;

                requestData.sendData["STR_ROW"] = strRow;
                requestData.sendData["END_ROW"] = endRow;

                // sendData 세팅
                requestData.sendData["CTCP_CODE"]=this.S_CTCP_CODE;
                requestData.sendData["BUSS_CODE"]=this.S_BUSS_CODE;
                requestData.sendData["START_DATE"]=this.DATES[0];
                requestData.sendData["END_DATE"]=this.DATES[1];
                requestData.sendData["DONG"]=this.fnGapReplace(this.S_DONG);
                requestData.sendData["HOSU"]=this.fnGapReplace(this.S_HOSU);
                requestData.sendData["ADDRESS"]=this.fnGapReplace(this.S_ADDRESS);
                requestData.sendData["CUST_NAME"]=this.S_CUST_NAME;
                requestData.sendData["CUST_PHONE"]=this.S_CUST_PHONE;
                requestData.sendData["CONTR_CUST_NAME"]=this.S_CONTR_CUST_NAME;
                requestData.sendData["CONTR_CUST_PHONE"]=this.S_CONTR_CUST_PHONE;
                requestData.sendData["CUST_RES_NO"]=this.S_CUST_RES_NO;
                requestData.sendData["CUST_PPST_CD"]=this.mixin_isEmpty(this.S_CUST_PPST_CDS) ? '' : this.S_CUST_PPST_CDS.join();     //초기성향
                requestData.sendData["PROC_STAT_CD"]=this.S_PROC_STAT_CD;   //진행상태
                requestData.sendData["OUT_IN_STATUS"]=this.S_OUT_IN_STATUS ? 'Y' : 'N';     //외부유입 여부
                
                requestData.sendData["PART_TYPE"]='MI';
                requestData.sendData["USE_YN"]='Y';

                let responseGridList = await this.common_postCall(requestData, progBoolChk);
                let resHeader = responseGridList.HEADER;
                let resData = responseGridList.DATA;

                // 데이터 조회 오류 시 alert
                if (resHeader.ERROR_FLAG) {
                    this.common_alert(this.alertMsg.error, "error");
                    return false;
                }else{

                    if(!this.mixin_isEmpty(resData)) {
                        this.PROC_STAT_CDS = [];
                        this.CUST_PPST_CDS = [];
    
                        this.totCount = resData[0].TOT_CNT;
                        this.pageCount = Math.ceil(this.totCount / this.itemsPerPage);
    
                        this.gridDataText=resData;
    
                        this.gridDataText.forEach((ele, i) => {
                            ele.IDX = Number(ele.IDX);
    
                            if(!this.mixin_isEmpty(ele.PROC_STAT_CD)) {
                                this.PROC_STAT_CDS[ele.IDX] = ele.PROC_STAT_CD;
                            }
    
                            if(!this.mixin_isEmpty(ele.CUST_PPST_CD)) {
                                this.CUST_PPST_CDS[ele.IDX] = ele.CUST_PPST_CD;
                            }
    
                            if(!this.mixin_isEmpty(ele.MKTG_AGRE_YN)) {
                                this.MKTG_AGRE_YNS[ele.IDX] = ele.MKTG_AGRE_YN;
                            }
    
                            if(!this.mixin_isEmpty(ele.PRSN_AGRE_YN)) {
                                this.PRSN_AGRE_YNS[ele.IDX] = ele.PRSN_AGRE_YN;
                            }
                        });
    
                        this.fnResult(responseGridList);
                    } else {
                        this.totCount = 0;
                        this.pageCount = 0;
                    }
                }
            },

            fnGapReplace(param) {
                return param.replaceAll(/[' ']/gi, '').trim();
            },

            fnResult(response){

                this.SELECT_ROWS = [];

                let excelGridDataHeaders_bak = [
                    { text:"No", value:"IDX", align:"center", width:"57px", sortable:true },
                    { text:"건설사", value:"CTCP_NAME", align:"center", width:"100px", sortable:true },
                    { text:"사업지", value:"BUSS_NAME", align:"center", width:"100px", sortable:true },
                    { text:"동", value:"DONG", align:"center", width:"77px", sortable:true },
                    { text:"호수", value:"HOSU", align:"center", width:"100px", sortable:true },
                    { text:"이름", value:"CONTR_CUST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"전화번호", value:"CONTR_CUST_PHONE", align:"center", width:"130px", sortable:true },
                    { text:"주민번호", value:"CUST_RES_NO", align:"center", width:"150px", sortable:true },
                    { text:"주소", value:"ADDRESS", align:"center", width:"200px", sortable:true },
                    { text:"카카오톡 이름", value:"CUST_NAME", align:"center", width:"110px", sortable:true },
                    { text:"카카오톡 핸드폰", value:"CUST_PHONE", align:"center", width:"120px", sortable:true },
                    { text:"마케팅 활용동의", value:"MKTG_AGRE_YN", align:"center", width:"110px", sortable:true },
                    { text:"개인정보수집", value:"PRSN_AGRE_YN", align:"center", width:"100px", sortable:true },
                    { text:"초기성향", value:"CUST_PPST_NAME", align:"center", width:"100px", sortable:true },
                    { text:"진행사항", value:"PROC_STAT_NAME", align:"center", width:"190px", sortable:true },
                ];

                this.excelGridDataHeaders = excelGridDataHeaders_bak;

                if (this.gridDataText.length === 0) {
                    return;
                }

                this.gridDataText = response.DATA;
                const res = response.DATA;
                let cnt = 0;

                for(var i in res) {
                    if(res[i]["QUTN_ITEM_NAME"] !== '') {
                        if( cnt == 0 ) {

                            // IMG_QUTN_RPLY
                            if(!this.mixin_isEmpty(res[i]["IMG_QUTN_RPLY"])) {
                                this.gridDataHeaders.push({ text:"이미지", value:"IMG_QUTN_RPLY", align:"center", width:"75px", sortable:true },)  
                                this.gridDataText[i]["IMG_QUTN_RPLY"] = res[i]["IMG_QUTN_RPLY"];
                            }

                            for(var d in res[i]["QUTN_ITEM_NAME"]) {
                                this.gridDataHeaders.push({ text: "질문"+(Number(d)+1), value: "QUTN_ITEM_NAME" + d,  align: "center", width: "120px", sortable: true },)
                                this.gridDataHeaders.push({ text: "답"+(Number(d)+1), value: "QUTN_ITEM_DETL_CODE" + d,  align: "center", width: "120px", sortable: true },)  
                                this.gridDataText[i]["QUTN_ITEM_NAME"+[d]] = res[i]["QUTN_ITEM_NAME"][d];
                                this.gridDataText[i]["QUTN_ITEM_DETL_CODE"+[d]] = res[i]["QUTN_ITEM_DETL_CODE"][d];

                                cnt = cnt + 1;  
                            }

                        }else{
                            for(var c in res[i]["QUTN_ITEM_NAME"]) {
                                if( c >= cnt ) {
                                    this.gridDataHeaders.push({ text: "질문"+(Number(c)+1), value: "QUTN_ITEM_NAME" + c,  align: "center", width: "120px", sortable: true },)
                                    this.gridDataHeaders.push({ text: "답"+(Number(c)+1), value: "QUTN_ITEM_DETL_CODE" + c,  align: "center", width: "120px", sortable: true },)  
                                    this.gridDataText[i]["QUTN_ITEM_NAME"+[c]] = res[i]["QUTN_ITEM_NAME"][c];
                                    this.gridDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = res[i]["QUTN_ITEM_DETL_CODE"][c];

                                    cnt = cnt + 1;
                                }else{
                                    this.gridDataText[i]["QUTN_ITEM_NAME"+[c]] = res[i]["QUTN_ITEM_NAME"][c];
                                    this.gridDataText[i]["QUTN_ITEM_DETL_CODE"+[c]] = res[i]["QUTN_ITEM_DETL_CODE"][c];
                                }
                            }
                        }
                    }
                }

                this.gridDataText.forEach((ele, i) => {
                    if(!this.mixin_isEmpty(ele.CUST_PPST_NAME)) {
                        ele['CUST_PPST_CD_BAK'] = ele.CUST_PPST_CD;
                        ele.CUST_PPST_CD = ele.CUST_PPST_NAME;
                    }
                    if(!this.mixin_isEmpty(ele.PROC_STAT_NAME)) {
                        ele['PROC_STAT_CD_BAK'] = ele.PROC_STAT_CD;
                        ele.PROC_STAT_CD = ele.PROC_STAT_NAME;
                    }
                });
            },

            fnGridRowClick(item, row) {
                this.ROW_NUMBER = item.IDX;
            },

            async fnGridRowDbClick(e, item) {
                this.OMP0410Param={
                    headerTitle:"계약 고객 수정",
                    type:"U",
                    btnText:'수정',
                    rowData:item,
                };

                this.fnShowDialog('OMP0410');
            },

            clickActive(item){
                return item.IDX == this.ROW_NUMBER ? 'active' : '';
            },

        },

        async mounted() {
            let userGroupCd = this.user_role.userGroupCd;

            this.USER_ID = this.user_id;

            let pArr = ['HLW_CTCP_CD', 'HLW_CUST_PPST_CD', 'HLW_PROC_STAT_CD'];

            this.allCodeList = await this.mixin_common_code_get_all(pArr);

            this.sUserPpsityCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CUST_PPST_CD', '');
            this.userPpsityCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CUST_PPST_CD', '선택');
            this.procStatItems = await this.mixin_common_code_get(this.allCodeList, 'HLW_PROC_STAT_CD', '선택');

            if (userGroupCd == "SYSTEMMANAGER") {
                this.USER_AUTH_BOOL = true;
                this.USER_AUTH_CHK = userGroupCd;
                this.ctcpTypeCds = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '선택');
            } else {
                
                if(userGroupCd == 'MIPROMOTER') {
                    this.USER_AUTH_BOOL = true; //기능 권한
                }else{
                    this.USER_AUTH_BOOL = false; //기능 권한
                }

                this.USER_AUTH_CHK = userGroupCd;
                let userAuths = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID);

                if (userAuths.length > 0) {
                    for (let i = 0; i < userAuths.length; i++) {
                        this.ctcpTypeCds.push({ CD_NM: userAuths[i].CTCP_NAME, CD: userAuths[i].CTCP_CODE });
                        this.bussTypeCds.push({ CD_NM: userAuths[i].BUSS_NAME, CD: userAuths[i].BUSS_CODE });
                    }

                    this.S_CTCP_CODE = this.ctcpTypeCds[0].CD;
                    this.S_BUSS_CODE = this.bussTypeCds[0].CD;
                }
            }
        },

        computed: {

            dateRangeText () {
                var newStartDate = this.DATES[0];
                var newEndDate = this.DATES[1];
                return newStartDate + " ~ " + newEndDate;
            },

            initHeaders(){
                return {
                };
            },

            paramData(){
                return {
                };
            }
        },
    }
</script>